<template>
  <div class="" >
    <div class="show-up-sm header-box" :style="headerStyle" :class="{color: color}">
      <div class="flex-align-center flex-between">
        <div class="logo-box">
          <router-link to="/">
            <template v-if="screenWidth >= 900">
              <img class="logo" src="../../assets/image/public/logo.svg" alt="">
            </template>
            <template v-else>
              <img class="logo-mini" src="../../assets/image/public/logo_mini.svg" alt="">
            </template>
          </router-link>
        </div>
        <div class="menu-box flex-align-center">
          <ul class="flex-align-center">
            <li v-for="(item, index) in tabList" :key="index" :class="{active:active == index}">
              <a target="_blank" v-if="index == tabList.length - 1" :href="item.path">{{item.title}}</a>
              <router-link v-else :to="item.path">{{item.title}}</router-link>
            </li>
          </ul>
        </div>
        <div class="header-right flex-align-center flex-end">
          <template v-if="isAuthenticated">
            <div class="right-item flex-align-center flex-center mr10">
              <a href="" target="_blank" @click.prevent="handleToPath('/dashboard')">控制台</a>
            </div>
            <a href="" target="_blank" @click.prevent="handleToPath('/dashboard')">
              <div class="primary-btn user-btn">{{ getUserName }}</div>
            </a>
          </template>
          <template v-else>
            <div class="right-item flex-align-center flex-center mr10">
              <a href="" target="_blank" @click.prevent="handleToPath('/user/login')">登录</a>
            </div>
            <a href="" target="_blank" @click.prevent="handleToPath('/user/register')">
              <div class="primary-btn register-btn">注册</div>
            </a>
          </template>
        </div>
      </div>
    </div>
    <div class="show-down-and-sm">
      <div class="header-box">
        <div class="flex-align-center flex-between">
          <div class="logo-box">
            <router-link to="/">
              <img class="logo-mini" src="../../assets/image/public/logo_mini.svg" alt="">
            </router-link>
          </div>
          <!-- > 390 && < 768-展示 -->
          <div class="show-up-xs menu-box flex-align-center" v-if="screenWidth >= 560">
          <ul class="flex-align-center">
            <li v-for="(item, index) in tabList" :key="index" :class="{active:active == index}">
              <a target="_blank" v-if="index == tabList.length - 1" :href="item.path">{{item.title}}</a>
              <router-link v-else :to="item.path">{{item.title}}</router-link>
            </li>
          </ul>
          </div>
          <div class="show-up-xs header-right" v-if="screenWidth >= 560">
            <div class="flex-align-center flex-end">
              <template v-if="isAuthenticated">
                <div class="right-item flex-align-center flex-center mr10">
                  <a href="" target="_blank" @click.prevent="handleToPath('/dashboard')">控制台</a>
                </div>
                <a href="" target="_blank" @click.prevent="handleToPath('/dashboard')">
                  <div class="primary-btn user-btn">{{ getUserName }}</div>
                </a>
              </template>
              <template v-else>
                <div class="right-item flex-align-center flex-center mr10">
                  <a href="" target="_blank" @click.prevent="handleToPath('/user/login')">登录</a>
                </div>
                <a href="" target="_blank" @click.prevent="handleToPath('/user/register')">
                  <div class="primary-btn register-btn">注册</div>
                </a>
              </template>
            </div>
          </div>
          <!-- 390及以下展示 -->
          <div v-if="screenWidth < 560">
            <div class="nav-icon flex-align-center flex-center" @mouseenter="!navActive" @mouseleave="navActive" @click="minDrawer = true">
              <img v-if="navActive" src="../../assets/image/public/nav-icon.svg" alt="">
              <img v-else src="../../assets/image/public/nav-icon-active.svg" alt="">
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <el-drawer
      class="hidden-sm-and-up"
      custom-class="drawer-box"
      size="37.5%"
      :visible.sync="minDrawer"
      :direction="direction"
      :modal-append-to-body='false'
      :show-close='false'>
      <div class="drawer-box">
        <el-menu
          class="el-menu-vertical-demo"
          :default-active="$route.path"
          router>
          <template v-if="isAuthenticated">
            <el-menu-item class="flex-align-center flex-center" >
              <a href="" target="_blank" @click.prevent="handleToPath('/dashboard')">
                <div class="primary-btn user-btn">{{ getUserName }}</div>
              </a>
            </el-menu-item>
            <el-menu-item class="flex-align-center flex-center" >
              <span slot="title" @click="handleToPath('/dashboard')">控制台</span>
            </el-menu-item>
            <el-menu-item class="flex-align-center flex-center" >
              <span slot="title" @click="handleExit()">退出</span>
            </el-menu-item>
          </template>
          <template v-else>
            <el-menu-item class="flex-align-center flex-center" >
              <div class="primary-btn register-btn" @click="handleToPath('/user/register')">注册</div>
            </el-menu-item>
            <el-menu-item class="flex-align-center flex-center" >
              <span slot="title" @click="handleToPath('/user/login')">登录</span>
            </el-menu-item>
          </template>
          <el-menu-item index="/" class="flex-align-center flex-center" >
            <span slot="title">主页</span>
          </el-menu-item>
          <el-menu-item index="/mirror" class="flex-align-center flex-center">
            <span slot="title">镜像中心</span>
          </el-menu-item>
          <el-menu-item index="/computing" class="flex-align-center flex-center">
            <span slot="title">算力服务器</span>
          </el-menu-item>
          <el-menu-item index="/news" class="flex-align-center flex-center">
            <span slot="title">新闻</span>
          </el-menu-item>
          <el-menu-item class="flex-align-center flex-center">
            <span slot="title" @click="handleToHelp()">帮助文件</span>
          </el-menu-item>
        </el-menu>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    active: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      tabList: [{
          path: "/",
          title: "主页"
        },
        {
          path: "/mirror",
          title: "镜像中心"
        },
        {
          path: "/computing",
          title: "算力服务器"
        },
        {
          path: "/news",
          title: "新闻"
        },
        {
          path: "https://help.spacehpc.com/",
          title: "帮助文档"
        }
      ],
      drawer: false,
      minDrawer: false,
      direction: 'rtl',
      style: {
        backgroundColor: '',
        marginTop: '',
      },
      scrollY: 0,
      color: false,
      navActive: true
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'tokenInfo', 'registerPhone', 'screenWidth']),
    getUserName() {
      if (this.tokenInfo) {
        let name = this.tokenInfo.name;
        if (name.length) {
          return name[0].toUpperCase();
        }
      }
      return "";
    },
    headerStyle() {
      const opacity = Math.min(0.15 + (this.scrollY / 300), 0.6);
      return {
        backgroundColor: `rgba(0, 0, 0, ${opacity})`, // 红色背景
        transition: 'background-color 0.3s',
      };
    }
  },
  mounted () {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions(["removeToken"]),
    // 向下滚动 头部背景色变化
    handleScroll () {
      this.scrollY = window.scrollY
      // let scrollTop =
      //   window.pageYOffset ||
      //   document.documentElement.scrollTop ||
      //   document.body.scrollTop;
      // //设置背景颜色的透明度
      // if (scrollTop) {
      //   console.log('scrollTop', scrollTop)
      //   this.style.marginTop = 0
      //   this.style.backgroundColor = `rgba(0, 0, 0,${scrollTop / (scrollTop + 80)})`;
      //   console.log('backgroundColor', this.style.backgroundColor);
        
      //   this.color = true;
      // } else if (scrollTop == 0) {
      //   this.style.backgroundColor = "rgba(0, 0, 0, 0.15)";
      //   this.style.marginTop = "";
      //   this.color = false;
      // }
    },
    // 跳转
    handleToPath(url) {
      try {
        let to_path = this.$config.basicUrl + url;
        if (url === '/user/register') {
          if (this.registerPhone) {
            to_path += `?phone=${this.registerPhone}`
          }
        }
        let open_window = window.open(to_path, '_blank');
        open_window.onload = function() {
          login_html.postMessage('官网发出的消息', url);
        };
      } catch (error) {
        console.log(error)
      }
    },
    // 跳转帮助
    handleToHelp() {
      window.open("https://spacehpc.feishu.cn/wiki/VB20w8aD6iwY4MkXG50cH6wnnqg", "_blank");
    },
    // 退出
    handleExit() {
      this.removeToken();
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch:{
    screenWidth(val) {
      // this.screenWidth = val
      // if (this.screenWidth > 768) {
      //   this.minDrawer = false
      // }
    }
  }
}
</script>
