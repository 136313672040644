<template>
<div class="footer container">
  <div class="footer-content flex-center">
    <div class="footer-row flex-center flex-align-start flex-column-direction">
      <div class="footer-list flex-center">
        <div class="footer_widget footer_start">
          <h4 class="mb20">服务支持</h4>
          <div class="widget_content">
            <ul>
              <li><a target="_blank" href="https://spacehpc.feishu.cn/wiki/VB20w8aD6iwY4MkXG50cH6wnnqg">帮助文档</a></li>
              <li><a target="_blank" href="https://spacehpc.feishu.cn/wiki/BH4Qw8szGiAKAXkz68kc1rr6nx9">用户协议</a></li>
            </ul>
          </div>
        </div>
        <div class="footer_widget">
          <h4 class="mb20">关于</h4>
          <div class="widget_content">
            <ul>
              <li>联系我们</li>
              <li>加入我们</li>
              <li>关于我们</li>
            </ul>
          </div>
        </div>
        <div class="footer_widget">
          <h4 class="mb20">产品</h4>
          <div class="widget_content">
            <ul>
              <li><a target="_blank" href="https://www.renderg.com/">RenderG云渲染</a></li>
              <li><a target="_blank" href="https://www.rendercool.com/">渲酷云渲染</a></li>
            </ul>
          </div>
        </div>
        <div class="footer_widget show-up-sm">
          <h4 class="mb20">公司地址</h4>
          <div class="widget_content">
            <ul>
              <li class="exclude">
                <!-- <span v-if="screenWidth > 1440">北京：</span> -->
                <span>北京市海淀区双清路77号双清大厦4号楼5层</span>
              </li>
              <li class="exclude">
                <!-- <span v-if="screenWidth > 1440">衢州：</span> -->
                <span>浙江省衢州市龙游县荣昌东路2号金融中心A幢A-1703</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="show-down-and-sm mt60">
        <div class="footer_widget">
          <h4 class="mb20">公司地址</h4>
          <div class="widget_content">
            <ul>
              <li class="exclude">
                <span v-if="screenWidth > 1440">北京：</span>
                <span>北京市海淀区双清路77号双清大厦4号楼5层</span>
              </li>
              <li class="exclude">
                <span v-if="screenWidth > 1440">衢州：</span>
                <span>浙江省衢州市龙游县荣昌东路2号金融中心A幢A-1703</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 友情链接 -->
  <div class="friendly-link-box ">
    <a class="friendly-link link-first-line" href="https://aigcrank.cn/" target="_blank" >
      <div >
        <img src="../../assets/image/index/partner/partner20.svg" alt="AIGCrank">
      </div>
    </a>
    <a class="friendly-link link-first-line" href="https://aishenqi.net/" target="_blank" >
      <div >
        <img src="../../assets/image/index/partner/partner21.svg" alt="AI神器大全">
      </div>
    </a>
    <a class="friendly-link link-first-line" href="https://www.mygptlife.com/" target="_blank" >
      <div >
        <img src="../../assets/image/index/partner/partner19.svg" alt="默拉智能">
      </div>
    </a>
    <a class="friendly-link link-first-line" href="https://www.aigcwx.com" target="_blank" >
      <div >
        <img src="../../assets/image/index/partner/partner22.svg" alt="AIGC微信站">
      </div>
    </a>
    <a class="friendly-link" href="https://aijuh.com/" target="_blank" >
      <div >
        <img src="../../assets/image/index/partner/partner17.svg" alt="爱聚合">
      </div>
    </a>
    <a class="friendly-link" href="https://hao.logosc.cn/" target="_blank" >
      <div >
        <img src="../../assets/image/index/partner/partner16.svg" alt="神器集">
      </div>
    </a>
    <a class="friendly-link" href="https://fyzai.com/" target="_blank" >
      <div >
        <img src="../../assets/image/index/partner/partner18.svg" alt="疯语咒AI">
      </div>
    </a>
  </div>
  <div class="copyright-line">
    <div class="footer-platform flex-center">
      <div v-for="(item, index) in platformList" :key="index" class="footer-platform-item">
        <el-popover placement="top" trigger="hover" popper-class="footer-popover">
          <div class="flex-algin-center flex-column footer-popover-content">
            <img :src="item.qrCode" class="footer-platform-item-qccode" />
            <p class="footer-platform-item-title">扫码关注{{ item.title }}</p>
          </div>
          <img :src="item.image" slot="reference" />
        </el-popover>
      </div>
    </div>
    <div class="footer-logo flex-center">
      <img class="logo-img" src="../../assets/image/public/logo.svg" />
    </div>
    <div class="Copyright flex-center">
      <p v-if="screenWidth > 670">北京润德骥图科技有限公司 Copyright ©{{year}} {{localhost}} 备案号： <a target="_blank" href="https://beian.miit.gov.cn">{{number}}</a></p>
      <div v-else class="flex-align-center flex-column-direction">
        <span>北京润德骥图科技有限公司</span>
        <span>&nbsp;Copyright ©{{year}}&nbsp;</span>
        <span>
          {{localhost}}
          备案号：<a target="_blank" href="https://beian.miit.gov.cn">{{number}}</a>
        </span>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  mapGetters
} from "vuex";
export default {
  computed: {
    ...mapGetters(['screenWidth']),
  },
  data() {
    return {
      localhost: 'spacehpc.com',
      number: '京ICP备2020049003号-1',
      platformList: [{
          image: require("../../assets/image/public/douyin.png"),
          url: "https://www.douyin.com/user/MS4wLjABAAAA6JtigcRTN5A4yY-IOqimKbbJcfGrobP-avfNbV_T5veCK8eI8nyQ24zgBAgzFt_1",
          qrCode: require("../../assets/image/footer/douyin.jpg"),
          title: "抖音",
          showQRcode: false
        },
        {
          image: require("../../assets/image/public/wechat.png"),
          url: "https://work.weixin.qq.com/kfid/kfc9bc28e0f847fbdb1",
          qrCode: require("../../assets/image/footer/wechat.jpg"),
          title: "公众号",
          showQRcode: false
        },
        {
          image: require("../../assets/image/public/bilibili.png"),
          url: "https://space.bilibili.com/3546671908653400?spm_id_from=333.337.0.0",
          qrCode: require("../../assets/image/footer/bilibili.jpg"),
          title: "哔哩哔哩",
          showQRcode: false
        },
        {
          image: require("../../assets/image/public/toutiao.png"),
          url: "https://www.toutiao.com/c/user/token/MS4wLjABAAAA1OVwGWBQvmxoZf0Q4zygUFaRZAxDSBmqQFpD4XPEJrVqIZKYhW9omcO1j1l2DbH2/?tab=all",
          qrCode: require("../../assets/image/footer/toutiao.jpg"),
          title: "头条",
          showQRcode: false
        },
      ]
    }
  },
  created() {
    this.year = new Date().getFullYear()
  },
  methods: {
    // 社交媒体跳转
    handlePlatformClick(url) {
      window.open(url, '_blank');
    }
  },
}
</script>
